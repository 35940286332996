import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { useSlug, useTranslationLink } from 'src/utils';
import { SEO } from 'src/components/common';
import { Content } from 'src/components/product';
import { GetBuildQuery } from 'graphql-types';
import { ProductPageContextProps, LangKeyType } from 'custom-types';

type Props = PageProps<GetBuildQuery, ProductPageContextProps>;

export const query = graphql`
  query GetBuild(
    $lang: String!
    $productUid: String!
    $brandUid: String!
    $seasonId: String!
  ) {
    site {
      siteMetadata {
        titleCustom {
          ja {
            build
          }
          en {
            build
          }
        }
        description {
          ja {
            build
          }
          en {
            build
          }
        }
      }
    }
    prismic {
      build(lang: $lang, uid: $productUid) {
        _meta {
          alternateLanguages {
            uid
            type
          }
        }
        title
        meta_title
        meta_description
        content
        images {
          image
          imageSharp {
            publicURL
          }
        }
      }
      ...ProductBuildContent
    }
  }
`;

type UseSEOProps = {
  site: GetBuildQuery['site'];
  build: GetBuildQuery['prismic']['build'];
  isSingle: boolean;
  langKey: LangKeyType;
};
function useSEO({ site, build, isSingle, langKey }: UseSEOProps) {
  return React.useMemo(() => {
    let title: string | null;
    if (isSingle) {
      title = build?.meta_title ? RichText.asText(build?.meta_title) : null;
    } else {
      if (langKey === 'ja') {
        title = site?.siteMetadata?.titleCustom?.ja?.build || null;
      } else {
        title = site?.siteMetadata?.titleCustom?.en?.build || null;
      }
    }
    let description: string | null;
    if (isSingle) {
      description = build?.meta_description
        ? RichText.asText(build?.meta_description)
        : null;
    } else {
      if (langKey === 'ja') {
        description = site?.siteMetadata?.description?.ja?.build || null;
      } else {
        description = site?.siteMetadata?.description?.en?.build || null;
      }
    }
    const image = build?.images?.[0].imageSharp?.publicURL || undefined;
    return {
      title,
      description,
      image,
      type: isSingle ? ('product' as const) : ('build' as const),
    };
  }, [site, build, isSingle, langKey]);
}

const Build: React.FC<Props> = ({
  data: { site, prismic },
  pageContext: { isSingle },
}: Props) => {
  const { slug, langKey } = useSlug();
  const seo = useSEO({
    site,
    build: prismic?.build,
    isSingle,
    langKey,
  });

  useTranslationLink({
    slug,
    langKey,
    uid: isSingle
      ? prismic.build?._meta?.alternateLanguages?.[0]?.uid
      : 'build',
    type: isSingle
      ? prismic.build?._meta?.alternateLanguages?.[0]?.type
      : 'brand-archive',
  });

  return (
    <>
      <SEO {...seo} />
      <Content {...prismic} isSingle={isSingle} />
    </>
  );
};

export default Build;
